import { NgModule } from '@angular/core';
import { EnrollmentChildDialogComponent } from './enrollment-child-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FunctionalButtonModule } from '@wam/component-library/functional-button';
import { CommonModule } from '@angular/common';
import { EnrollmentChildFormModule } from './enrollment-child-form/enrollment-child-form.module';
import { EnrollmentChildFormComponent } from './enrollment-child-form/enrollment-child-form.component';

@NgModule({
  declarations: [EnrollmentChildDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    FunctionalButtonModule,
    EnrollmentChildFormModule,
  ],
  exports: [EnrollmentChildDialogComponent, EnrollmentChildFormComponent],
})
export class EnrollmentChildDialogModule {}
