import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@wam/component-library/button';
import { TranslateModule } from '@ngx-translate/core';
import { FunctionalButtonModule } from '@wam/component-library/functional-button';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormioAppConfig, FormioModule } from '@formio/angular';
import { UpstartIntakeDataService } from '@app/shared/upstart-intake-data.service';
import { ProgramsClaimsService } from '@app/programs/programs-claims/programs-claims.service';
import { EnrollmentFacilityDialogComponent } from './enrollment-facility-dialog.component';

@NgModule({
  declarations: [EnrollmentFacilityDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    TranslateModule,
    FunctionalButtonModule,
    MatProgressSpinnerModule,
    FormioModule,
  ],
  providers: [
    UpstartIntakeDataService,
    ProgramsClaimsService,
    { provide: FormioAppConfig, useValue: {} },
  ],
  exports: [EnrollmentFacilityDialogComponent],
})
export class EnrollmentFacilityDialogModule {}
