import { ProgramsClaimsState } from './programs-claims.state';
import { createReducer, on } from '@ngrx/store';
import * as programsClaimsActions from './programs-claims.actions';
import { orderBy } from 'lodash-es';

const initialState: ProgramsClaimsState = {
  isProgramsClaimsLoading: false,
  isFacilityInfoLoading: false,
  programsClaims: [],
  selectedProgramsClaim: null,
  facilityInfo: null,
};

const programsClaimsReducer = createReducer(
  initialState,
  on(programsClaimsActions.loadProgramsClaims, (state) => ({
    ...state,
    isProgramsClaimsLoading: true,
  })),
  on(programsClaimsActions.loadProgramsClaimsSuccess, (state, { programsClaims }) => ({
    ...state,
    programsClaims: orderBy(programsClaims, ['formSubmission.childLabel'], ['asc']),
    // update the selected programs claim with fresh data or choose first item
    selectedProgramsClaim:
      programsClaims.find((claim) => claim.id === state.selectedProgramsClaim?.id) ??
      orderBy(programsClaims, ['formSubmission.childLabel'], ['asc'])[0],
    isProgramsClaimsLoading: false,
  })),
  on(programsClaimsActions.loadProgramsClaimsFail, (state) => ({
    ...state,
    isProgramsClaimsLoading: false,
  })),
  on(programsClaimsActions.addProgramsClaim, (state, { programsClaim }) => ({
    ...state,
    programsClaims: orderBy(
      [programsClaim, ...state.programsClaims],
      ['formSubmission.childLabel'],
      ['asc'],
    ),
  })),
  on(programsClaimsActions.updateSelectedProgramsClaim, (state, { programsClaim }) => ({
    ...state,
    selectedProgramsClaim: programsClaim,
  })),
  on(programsClaimsActions.updateSelectedProgramsClaimData, (state, { programsClaim }) => ({
    ...state,
    selectedProgramsClaim: programsClaim,
    programsClaims: orderBy(
      [
        ...state.programsClaims.filter((claim) => claim.id !== state.selectedProgramsClaim.id),
        programsClaim,
      ],
      ['formSubmission.childLabel'],
      ['asc'],
    ),
  })),
  on(programsClaimsActions.loadFacilityInfo, (state) => ({
    ...state,
    isFacilityInfoLoading: true,
  })),
  on(programsClaimsActions.loadFacilityInfoSuccess, (state, { facilityInfo }) => ({
    ...state,
    facilityInfo,
    isFacilityInfoLoading: false,
  })),
  on(programsClaimsActions.loadFacilityInfoFail, (state) => ({
    ...state,
    isFacilityInfoLoading: false,
  })),
);

export function reducer(state, action) {
  return programsClaimsReducer(state, action);
}
