import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationsService, NotificationType } from '@wam/notifications';
import { Injectable } from '@angular/core';
import { ProgramsClaimsService } from '../programs-claims.service';
import * as programsClaimsActions from './programs-claims.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { FacilityInfo, ProgramsClaim } from './programs-claims.model';

@Injectable()
export class ProgramsClaimsEffects {
  loadProgramsClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(programsClaimsActions.loadProgramsClaims),
      mergeMap(({ formId }) =>
        this.programsClaimsService.getSubmissionsByFormId(formId).pipe(
          map((programsClaims) =>
            programsClaimsActions.loadProgramsClaimsSuccess({ programsClaims }),
          ),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(programsClaimsActions.loadProgramsClaimsSuccess({ programsClaims: [] }));
            } else {
              return of(programsClaimsActions.loadProgramsClaimsFail({ error: error.message }));
            }
          }),
        ),
      ),
    ),
  );

  loadProgramsClaimsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programsClaimsActions.loadProgramsClaimsFail),
        tap((error) =>
          this.notificationsService.notify(
            NotificationType.ERROR,
            'There was an error while trying to load children.',
          ),
        ),
      ),
    { dispatch: false },
  );

  loadFacilityInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(programsClaimsActions.loadFacilityInfo),
      mergeMap(({ formId }) =>
        this.programsClaimsService.getSubmissionsByFormId(formId).pipe(
          map((facilityInfo) =>
            programsClaimsActions.loadFacilityInfoSuccess({
              facilityInfo: facilityInfo[0] as unknown as ProgramsClaim<FacilityInfo>,
            }),
          ),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(programsClaimsActions.loadFacilityInfoSuccess(null));
            } else {
              return of(programsClaimsActions.loadFacilityInfoFail({ error: error.message }));
            }
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private programsClaimsService: ProgramsClaimsService,
    private notificationsService: NotificationsService,
  ) {}
}
