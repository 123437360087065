<div class="programs-claims__switch">
  <div class="programs-claims__switch__title">
    {{ 'Manage Children' | translate }}
  </div>

  <div
    *ngIf="isProgramsClaimsLoading$ | async"
    class="programs-claims__loading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>

  <div
    *ngIf="!(isProgramsClaimsLoading$ | async)"
    class="programs-claims__switch__items">
    <div
      *ngFor="let claim of programsClaims$ | async"
      class="programs-claims__switch__item option">
      <input
        (change)="updateSelected(claim); $event.preventDefault()"
        [checked]="claim.id === (selectedClaim$ | async).id"
        [id]="claim.formSubmission.childLabel + ' radio'"
        [value]="claim"
        name="child-{{ uniqueId }}"
        type="radio" />
      <label
        (change)="updateSelected(claim); $event.preventDefault()"
        [for]="claim.formSubmission.childLabel + ' radio'"
        class="programs-claims__switch__item__label">
        <span></span>
        {{ claim.formSubmission.childLabel }}
        <cl-icon
          (click)="openEnrollmentWizardDialog(true); $event.preventDefault()"
          [icon]="Icon.settingsBlue"
          size="24px"></cl-icon>
      </label>
      <div
        class="programs-claims__switch__item__status"
        *ngIf="claim.programEnrollmentStatusTranslated"
        [class.completed]="claim.programEnrollmentStatus === ProgramEnrollmentStatus.APPROVED"
        [class.waiting-approval]="
          claim.programEnrollmentStatus === ProgramEnrollmentStatus.PENDING_CONFIRMATION
        "
        [class.on-waitlist]="claim.programEnrollmentStatus === ProgramEnrollmentStatus.WAITLISTED"
        [class.in-progress]="claim.programEnrollmentStatus === ProgramEnrollmentStatus.IN_PROGRESS"
        [class.ineligible]="claim.programEnrollmentStatus === ProgramEnrollmentStatus.REJECTED"
        [class.unavailable]="claim.programEnrollmentStatus === ProgramEnrollmentStatus.UNAVAILABLE">
        {{ claim.programEnrollmentStatusTranslated }}
      </div>
    </div>
  </div>

  <cl-button
    (click)="openEnrollmentWizardDialog()"
    *ngIf="!(isProgramsClaimsLoading$ | async)"
    [thin]="true"
    class="programs-claims__switch__add-child"
    id="programs-claims-add-child">
    {{ 'Add a Child' | translate }}
  </cl-button>
</div>
