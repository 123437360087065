import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { IconModule } from '@wam/icons';
import { TranslateModule } from '@ngx-translate/core';
import { EnrollmentChildDialogModule } from './enrollment-child-dialog/enrollment-child-dialog.module';
import { EnrollmentTypeDialogModule } from './enrollment-type-dialog/enrollment-type-dialog.module';
import { EnrollmentFacilityDialogModule } from './enrollment-facility-dialog/enrollment-facility-dialog.module';
import { ProgramsClaimsModule } from '../programs-claims/programs-claims.module';
import { EnrollmentChildFormComponent } from './enrollment-child-dialog/enrollment-child-form/enrollment-child-form.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    IconModule,
    TranslateModule,
    ProgramsClaimsModule,
    EnrollmentTypeDialogModule,
    EnrollmentChildDialogModule,
    EnrollmentFacilityDialogModule,
  ],
  exports: [EnrollmentChildFormComponent],
})
export class EnrollmentDialogsModule {}
