import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@wam/component-library/button';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramsClaimsComponent } from './programs-claims.component';
import { ProgramsClaimsService } from './programs-claims.service';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/programs-claims.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProgramsClaimsEffects } from './state/programs-claims.effects';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconModule } from '@wam/icons';

@NgModule({
  declarations: [ProgramsClaimsComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature('programs-claims', reducer),
    EffectsModule.forFeature([ProgramsClaimsEffects]),
    MatDialogModule,
    ButtonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    IconModule,
  ],
  exports: [ProgramsClaimsComponent],
  providers: [ProgramsClaimsService],
})
export class ProgramsClaimsModule {}
