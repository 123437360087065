import { Component } from '@angular/core';
import { Icon } from '@wam/icons';
import { select, Store } from '@ngrx/store';
import * as programsClaimsActions from './state/programs-claims.actions';
import * as fromProgramsClaims from './state/programs-claims.selectors';
import { ProgramsClaim } from './state/programs-claims.model';
import { EnrollmentWizardDialogService } from '../enrollment-dialogs/enrollment-wizard-dialog.service';
import { ProgramEnrollmentStatus } from '@wap/enrollment-v2';

@Component({
  selector: 'programs-claims',
  templateUrl: './programs-claims.component.html',
  styleUrls: ['./programs-claims.component.scss'],
})
export class ProgramsClaimsComponent {
  Icon = Icon;
  ProgramEnrollmentStatus = ProgramEnrollmentStatus;
  programsClaims$ = this.store.pipe(select(fromProgramsClaims.getProgramsClaims));
  selectedClaim$ = this.store.pipe(select(fromProgramsClaims.getSelectedProgramsClaim));
  isProgramsClaimsLoading$ = this.store.pipe(select(fromProgramsClaims.getIsProgramsClaimsLoading));

  uniqueId = Math.random().toString(36).substring(2, 7);

  constructor(
    private store: Store,
    private enrollmentWizardDialog: EnrollmentWizardDialogService,
  ) {}

  openEnrollmentWizardDialog(editMode = false): void {
    this.enrollmentWizardDialog.openChildDialog(editMode);
  }

  updateSelected(claim: ProgramsClaim): void {
    this.store.dispatch(
      programsClaimsActions.updateSelectedProgramsClaim({ programsClaim: claim }),
    );
  }
}
