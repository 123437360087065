import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { EnrollmentTypeDialogComponent } from './enrollment-type-dialog.component';
import { FunctionalButtonModule } from '@wam/component-library/functional-button';
import { ButtonModule } from '@wam/component-library/button';
import { NavigationButtonModule } from '@wam/component-library/navigation-button';

@NgModule({
  declarations: [EnrollmentTypeDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    FunctionalButtonModule,
    ButtonModule,
    NavigationButtonModule,
  ],
  providers: [],
  exports: [EnrollmentTypeDialogComponent],
})
export class EnrollmentTypeDialogModule {}
